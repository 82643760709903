<template>
  <section class="section">
    <div class="container">
      <div class="row justify-content-center mt-09">
        <div class="col-12 text-center pb-2">
          <div class="section-title pb-3">
            <h4 class="title mb-4 font-weight-normal text-secondary">Tienes preguntas?</h4>
            <p class="text-muted para-desc mx-auto mb-0">
              hemos recopilado las
              <span class="text-primary font-weight-normal">preguntas mas
              Frecuentes</span>  de nuestros ususarios para poder solucionar tus
              problemas .
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="
                  fea
                  icon-ex-md
                  text-primary
                  mr-2
                  mt-1
                  feather feather-help-circle
                "
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <div class="media-body">
                <h5 class="mt-0 font-weight-light text-secondary">
                  ¿Que es <span class="text-primary font-weight-normal"> PetDr.Plan </span>?
                </h5>
                <p class="answer text-muted mb-0">
                  <!-- PetDr. Plan es el primer seguro de salud para mascotas en la
                  República Dominicana con el respaldo de Seguros Sura, S.A. –
                  PetDr. Plan cuenta con servicio al cliente a nivel nacional
                  las 24 horas del día a través de una Red de Clínicas
                  Veterinarias Referidas especialmente seleccionada para el
                  bienestar de su mascota. Los clientes pueden adquirir
                  diferentes planes para sus mascotas y funcionan de manera muy
                  similar a un plan de salud pero con algunas diferencias. Para
                  la utilización de las coberturas, beneficios y servicios de
                  los planes, nuestro cliente debe siempre entrar en contacto
                  con nuestro Contact Center +1 809-985-4527 antes de dirigirse
                  a cualquier clínica. Al entrar en contacto con PetDr. Plan,
                  nuestros asesores podrán orientarle sobre los cuidados y
                  prestadores de servicios que mejor satisfagan sus necesidades
                  inmediatas. -->

                  PetDr. Plan primer seguro para mascotas en Republica
                  Dominicana con respaldo de Seguros Sura, S.A, Los clientes
                  pueden adquirir diferentes planes para sus mascotas y
                  funcionan de manera muy similar a un plan de salud pero con
                  algunas diferencias.
                </p>
              </div>
            </div>
          </div>
          <!-- en PetDr. Plan contamos con Servicio al cliente
                  a nivel nacional las 24 H.  -->
          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="
                  fea
                  icon-ex-md
                  text-primary
                  mr-2
                  mt-1
                  feather feather-help-circle
                "
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <div class="media-body">
                <h5 class="mt-0 font-weight-light text-secondary">
                  ¿ <span class="text-primary font-weight-normal">PetDr. Plan</span> permite cualquier clinica veterinaria ?
                </h5>
                <p class="answer text-muted mb-0">
                  <!-- El cliente PetDr. Plan podrá visitar cualquier clínica que se
                  encuentre dentro de nuestra red de prestadores médicos
                  veterinarios, y deberá siempre llamar primero a nuestro
                  Contact Center +1 809-985-4527 para obtener la mejor atención
                  y orientación sobre los cuidados de su mascota, y las clínicas
                  más cercanas que ofrecen los servicios y especialidades
                  requeridas. -->

                  Siempre y cuando la clinica se encuentre dentro de nuestra 
                  red de instituciones medicas para obtener informacion de 
                  contacto con nuestros prestadores bastacon llamar a nuestro 
                  contact center +1 809-985-4527.

                  <!-- El cliente PetDr. Plan podra visitar cualquier clinica siempre y cuando
                  se encuentre dentro de nuestra red de medicos veterinarios.
                  para obtener informacion de contacto con nuestros prestadores basta
                  con llamar a nuestro contact center +1 809-985-4527. -->

                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="
                  fea
                  icon-ex-md
                  text-primary
                  mr-2
                  mt-1
                  feather feather-help-circle
                "
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <div class="media-body">
                <h5 class="mt-0 font-weight-light text-secondary">
                  ¿Hay limite de tiempo para los planes <span class="text-primary font-weight-normal">PetDr. Plan</span>?
                </h5>
                <p class="answer text-muted mb-0">
                  No, nuestros clientes pueden cancelar sin ningun impedimento 
                  los planes por escrito con por lo menos 15 días de anticipación.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="
                  fea
                  icon-ex-md
                  text-primary
                  mr-2
                  mt-1
                  feather feather-help-circle
                "
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <div class="media-body">
                <h5 class="mt-0 font-weight-light text-secondary">

                  ¿ es necesario llamar al Centro de Contacto<span class="text-primary font-weight-normal"> PETFONE </span> ?

                  <!-- ¿POR QUÉ EL CLIENTE DEBE SIEMPRE LLAMAR AL CENTRO DE CONTACTO
                  (PETFONE)? -->
                </h5>
                <p class="answer text-muted mb-0">

                  Recomendamos llamar a nuestro PETFONE <span class="font-weight-bold text-secondary">( +1 809-985-4527 ),</span> 
                  ya que en caso de Emergencia te brindaremos la mejor y 
                  mas rapida atencion para tu mascota .


                  <!-- En caso de una emergencia, existe una probabilidad de que
                  algunas de las clínicas estén cerradas o con los veterinarios
                  ocupados. Es importante para nosotros que su mascota sea
                  atendida lo antes posible, por eso recomendamos llamar primero
                  al Contact Center +1 809-985-4527. -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>

<style>
.mt-09 {
  margin-top: 125px;
}
</style>